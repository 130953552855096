// Profile API

import { GetAPIURL, RequestParams } from "@/utils/request";

export interface UserProfile {
    username: string;
    role: "unverified" | "lender" | "borrower"
    name: string;
    image: string;
    url: string;
    location: string;
    bio: string;
    created: number;
    averageRating: string;
    ratingsCount: number;
    defaultRisk: number;
    signedContractsCount: number;
    finishedContractsCount: number;
    cancelledDefaultContractsCount: number;
}

export class ProfileAPI {
    public static GetProfile(uid: string): RequestParams<UserProfile> {
        return {
            method: "GET",
            url: GetAPIURL("/profile/" + encodeURIComponent(uid)),
        };
    }

    public static GetProfileImageById(id: string): RequestParams<{image: string}> {
        return {
            method: "GET",
            url: GetAPIURL("/profile/" + encodeURIComponent(id) + "/image/default"),
        };
    }

    public static ChangeProfile(profile: {name: string, url: string, location: string, bio: string}): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/profile"),
            json: profile,
        };
    }

    public static DeleteProfileData(): RequestParams<void> {
        return {
            method: "DELETE",
            url: GetAPIURL("/profile-data"),
        };
    }

    public static ChangeProfileImage(image: File): RequestParams<{url: string}> {
        const form = new FormData();
        form.append("image", image);
        return {
            method: "POST",
            url: GetAPIURL("/profile/image"),
            form: form,
        };
    }

    public static DeleteProfileImage(): RequestParams<void> {
        return {
            method: "DELETE",
            url: GetAPIURL("/profile/image"),
        };
    }
}
