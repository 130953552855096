
import { AppEvents } from "@/control/app-events";
import { defineComponent, nextTick } from "vue";
import { useVModel } from "../../utils/vmodel";
import { FocusTrap } from "../../utils/focus-trap";
import { Request } from "@/utils/request";
import { NotificationsController } from "@/control/notifications";
import { NotificationsAPI } from "@/api/api-notifications";
import { Timeouts } from "@/utils/timeout";
import { AuthController } from "@/control/auth";
import { renderDate, renderDateAndTime } from "@/utils/time-utils";
  
export default defineComponent({
  name: "NotificationsModal",
  emits: ["update:display"],
  props: {
    display: Boolean,
  },
  setup(props) {
    return {
      displayStatus: useVModel(props, "display"),
    };
  },
  data: function () {
    return {
      unread: NotificationsController.UnreadCount,
      busyRead: false,

      loading: true,

      notifications: [],
    };
  },
  methods: {
    updateUnread: function () {
      this.unread = NotificationsController.UnreadCount;
    },

    load: function () {
      this.loading = true;

      Timeouts.Abort("notifications-modal-load");
      Request.Abort("notifications-modal-load");

      if (!AuthController.isAuthenticated()) {
        return;
      }

      this.notifications = [];

      let oldest = Date.now();

      if (this.notifications.length > 0) {
        oldest = this.notifications[this.notifications.length - 1].date;
      }

      Request.Pending("notifications-modal-load", NotificationsAPI.List(oldest)).onSuccess(response => {
        for (let i = 0; i < 3 && i < response.length; i++) {
          this.notifications.push(response[i]);
        }
        this.loading = false;
      }).onRequestError(err => {
        Request.ErrorHandler()
          .add(401, "*", () => {
            AppEvents.Emit("unauthorized");
          })
          .add(403, "*", () => {
            this.close();
          })
          .add("*", "*", () => {
            // Retry
            Timeouts.Set("notifications-modal-load", 2000, this.load.bind(this));
          })
          .handle(err);
      }).onUnexpectedError(err => {
        console.error(err);
        // Retry
        Timeouts.Set("notifications-modal-load", 2000, this.load.bind(this));
      });
    },

    open: function () {
      this.displayStatus = true;
      this.load();
    },

    close: function () {
      this.displayStatus = false;
    },

    markRead: function () {
      if (this.busyRead) {
        return;
      }
      this.busyRead = true;
      Request.Do(NotificationsAPI.Read(Date.now())).onSuccess(() => {
        this.busyRead = false;
        this.unread = 0;
        NotificationsController.Load();
      }).onRequestError(err => {
        this.busyRead = false;
        Request.ErrorHandler()
          .add(401, "*", () => {
            AppEvents.Emit("unauthorized");
          })
          .handle(err);
      }).onUnexpectedError(err => {
        this.busyRead = false;
        console.error(err);
      });
    },

    seeAllNotifications: function () {
      this.close();
      this.$router.push({ name: "notifications" });
    },

    escapeToClose: function (event) {
      if (event.key === "Escape") {
        this.close();
      }
    },

    stopPropagationEvent: function (e) {
      e.stopPropagation();
    },

    clickOnEnter: function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
        event.target.click();
      }
    },

    renderDateTime: function (t: number) {
      return renderDateAndTime(t, this.$t);
    },

    renderDate: function (d) {
      return renderDate(d, this.$t);
    },
  },
  mounted: function () {
    this.$options.openH = this.open.bind(this);
    AppEvents.AddEventListener("notifications-modal-load", this.$options.openH);

    this.$options.updateUnreadH = this.updateUnread.bind(this);
    AppEvents.AddEventListener("notifications-status-changed", this.$options.updateUnreadH);

    this.$options.focusTrap = new FocusTrap(this.$el, this.close.bind(this));
  },
  beforeUnmount: function () {
    AppEvents.RemoveEventListener("notifications-status-changed", this.$options.updateUnreadH);

    AppEvents.RemoveEventListener("notifications-modal-load", this.$options.openH);

    this.$options.focusTrap.destroy();
  },
  watch: {
    display: function () {
      if (this.display) {
        this.load();
        this.$options.focusTrap.activate();
        nextTick(() => {
          this.$el.focus();
        });
      } else {
        this.$options.focusTrap.deactivate();
        Timeouts.Abort("notifications-modal-load");
        Request.Abort("notifications-modal-load");
      }
    },
  },
});
